.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  background-color: #eee;
  width: 100%;
  height: 100vh;
}

.login_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.card {
  padding: 10px 20px;
}

.card_header {
  text-align: center;
}
