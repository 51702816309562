.conersation_card {
  max-width: 650px;
  min-width: 320px;
  margin: 5px;
  padding: 5px 10px;
  border-radius: 5px;
}

.card_highlighted {
  background-color: #eee;
}

.card_avatar {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.card_avatar b {
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-size: 14px;
  margin: 0 6px;
}

.card_avatar img {
  width: 40px;
  height: 40px;
  border-radius: 20px;
}

.description {
  margin: 5px 10px 0px 45px;
}
