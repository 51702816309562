.category_btn {
  height: 150px;
  width: 210px;
  text-transform: capitalize;
  margin: 5px 0;
}

.question_wrapper {
  margin: 0 5px;
  width: 620px;
}

.question_card {
  margin: 5px;
  background-color: #eee;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.question_card h3 {
  padding: 0;
  margin: 0;
}

.question_card p {
  align-self: flex-end;
  width: fit-content;
  padding: 0;
  margin: 0;
  color: #929191;
  font-size: 14px;
}

.question_card:hover {
  cursor: pointer;
  border: 1px solid #ccc;
}

.question_card:active {
  border: 1px solid #3472f7;
  background-color: #3472f7;
  color: #fff;
}

.question_card:active p {
  color: #fff;
}

.coversation_wrapper {
  margin: 0 5px;
  width: 520px;
  height: 80%;
  overflow: auto;
}

.conversation_form {
  position: fixed;
  width: 520px;
  bottom: 0;
  border-top: 1px solid #ccc;
  padding: 5px;
  background-color: #fff;
}

.form_btn {
  justify-content: flex-end;
  display: flex;
  margin: 8px 0 0 0;
}
