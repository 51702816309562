.row:hover {
  cursor: pointer;
}

.backBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px 0 0;
  z-index: 22;
}

.create_btn_icon {
  margin: 0 0 0 7px;
  font-size: 12px;
  font-weight: bolder;
}
