.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #b3b3b3;
  height: 40px;
  border-radius: 5px;
  overflow: hidden;
  padding: 0 5px;
}

.wrapper input {
  border: none;
  flex: 1;
  height: 38px;
}
.wrapper:focus-within {
  border: 2px solid #1e62f0;
}
