.wrapper {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: flex-end;
}

.container {
  position: fixed;
  height: 100%;
  background-color: #fff;
  width: 650px;
  overflow: auto;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.closeBtn {
  width: 40px;
  height: 40px;
}
