.create_btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.create_btn_icon {
  margin: 0 0 0 7px;
  font-weight: 700;
}

.header_col {
  display: flex;
  justify-content: flex-end;
}
