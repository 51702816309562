.form_video_wrapper {
  display: flex;
  width: 98%;
  height: 180px;
  padding: 5px;
  overflow: auto hidden;
  align-items: center;
  background-color: #eee;
  border-radius: 5px;
  border: 1px solid #dad9d9;
}

.form_video {
  min-width: 135px;
  min-height: 135px;
  max-width: 135px;
  max-height: 135px;
  margin: 10px;
  overflow: hidden;
  border-radius: 5px;
  background-color: #c5c5c5;
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;
}

.form_video:hover {
  cursor: pointer;
}

.active {
  border: 2px solid #ff9114;
}

.form_video video {
  min-width: 135px;
  min-height: 135px;
  max-width: 135px;
  max-height: 135px;
}

.form_video iframe {
  min-width: 135px;
  min-height: 110px;
  max-width: 135px;
  max-height: 110px;
}

.form_video iframe:hover {
  cursor: pointer;
}

.form_video button {
  position: absolute;
  z-index: 9;
  right: 5px;
  top: 5px;
}

.file_input {
  display: none;
}

.add_video_btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 135px;
  max-height: 135px;
  width: 100%;
}

.add_video_btn_icon {
  margin: 0 0 0 7px;
  font-size: 30px;
}

.inputWrapper {
  position: relative;
}

.blockOverlay {
  position: absolute;
  background-color: rgba(238, 238, 238, 0.35);
  width: 100%;
  height: 100%;
  z-index: 999;
  border: 1px solid #eee;
  border-radius: 5px;
}

.img_viewer {
  resize: contain;
  min-width: 135px;
  min-height: 135px;
  max-width: 135px;
  max-height: 135px;
}
