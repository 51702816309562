.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.header div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.backBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px 0 0;
}

.create_btn_icon {
  margin: 0 0 0 7px;
  font-size: 12px;
  font-weight: bolder;
}
