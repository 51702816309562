.modal_class {
  background-color: pink;
}

.category_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #eee;
  border: 1px solid #ede;
  border-radius: 5px;
  flex-wrap: wrap;
}

.category_btn {
  margin: 7px 5px;
}

.tag_wrapper {
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 3px 0 #9e9e9e;
}

.tag_wrapper p {
  padding: 5px;
  border-bottom: 1px solid #ccc;
  margin: 0;
  text-transform: capitalize;
}

.tag_wrapper p:hover {
  background-color: #eee;
  cursor: pointer;
}

.tag_wrapper p:last-child {
  border-bottom: 0;
}

.tag_view {
  display: flex;
  flex-direction: row;
  margin: 5px 0;
}

.tag_view div {
  padding: 0 0 0 8px;
  margin: 0px 2px 0px 0;
  border: 1px solid #ccc;
  background-color: #eee;
}

.tag_view button {
  border: 0;
  background-color: #ccc;
  width: 30px;
  margin-left: 4px;
}

.tag_view button:hover {
  background-color: #a7a7a7;
  color: #fff;
}

.tag_add_new {
  /* border: 1px solid #1e6dff; */
  color: #fff;
  background-color: #1e6dff;
}

.tag_add_new:hover {
  background-color: #1365ff !important;
}
