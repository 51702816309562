.accordion_item {
  border: 1px solid #ccc;
  padding: 0;
  overflow: hidden;
}

.accordion_item_header {
  margin: 0;
  width: 100%;
  font-size: 18px;
}

.accordion_item_header button {
  display: flex;
  justify-content: space-between;
  border: none;
  width: 100%;
  text-align: left;
  padding: 8px 15px;
}

.accordion_item_body {
  padding: 0;
  margin-top: 20px;
}

.question_item {
  border: 1px solid #eee;
  padding: 10px;
  background-color: #eee;
}

.question_item strong {
  font-weight: 600;
}

.question_item:hover {
  background-color: #ccc;
  cursor: pointer;
}

.no_more_questions:hover {
  background-color: #eee;
  cursor: no-drop;
}

.view_all {
  display: flex;
  justify-content: flex-end;
  margin: 5px 30px 20px 0;
}

.modal_body {
  max-height: 70vh;
  overflow: hidden auto;
}

.question {
  border-bottom: 1px solid #ccc;
  margin: 10px 0;
}

.select {
  display: flex;
  flex: 1;
  padding: 8px 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin: 0 0 5px 0;
  width: 100%;
}

.separate {
  margin: 5px 0;
}
