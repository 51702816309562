.icon_style_delete {
  color: red;
}

.icon_style_arrow {
  font-size: 20px;
  font-weight: bolder;
  color: #000;
}

.inline {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 5px;
}

.input {
  width: 520px;
  border: 0;
  border-bottom: 1px solid #ccc;
  border-radius: 0;
}

.input:focus-within {
  border: 0;
  border-bottom: 1px solid #000;
}

.checkbox {
  width: 20px;
  height: 20px;
  margin: 0 5px 0 0;
}

.select {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #d3d3d3;
  margin: 5px 0;
}

.add_option {
  border-bottom: 1px solid #ccc;
  background-color: #eee;
  width: 100%;
  padding: 5px;
  margin: 5px 0;
  display: flex;
  align-items: center;
}
