.wrapper {
  width: 100%;
  background-color: #eee;
  border: 2px solid #ccc;
  border-radius: 10px;
  overflow: hidden auto;
  padding: 5px;
  margin: 10px 0;
}

.wrapper button {
  border: none;
  background-color: #cecece;
  margin: 0 2px;
  padding: 0px 10px;
  border-radius: 5px;
}

.wrapper button:active {
  background-color: #4747ce;
  color: #fff;
}

.controller {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  background-color: #dfdbdb;
  padding: 10px 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: 2px solid #ccc;
}
