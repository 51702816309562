.container {
  position: relative;
  height: 100%;
  background-color: #fff;
}

.container-enter {
  width: auto;
}
.container-enter-active {
  width: auto;
  transition: width 500ms ease-out;
}
.container-exit {
  width: auto;
}
.container-exit-active {
  width: 0%;
  transition: width 500ms ease-out;
}
