.modal_body {
  max-height: 70vh;
  overflow: hidden auto;
}

.question {
  border-bottom: 1px solid #ccc;
  margin: 10px 0;
}

.select {
  display: flex;
  flex: 1;
  padding: 8px 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin: 0 0 5px 0;
  width: 100%;
}

.separate {
  margin: 5px 0;
}
